var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"content"},[_vm._m(1),_c('div',{staticClass:"news"},[_c('div',{staticClass:"title"},[_vm._v("公司新闻")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"en-title"},[_c('div',[_vm._v("Company News")]),_c('div',{staticClass:"more",on:{"click":function($event){return _vm.viewMore('公司新闻')}}},[_c('div',[_vm._v("查看更多 >")]),_c('div',{staticClass:"line"})])]),_c('div',{staticClass:"news-content"},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":_vm.newsList[0].coverImage,"alt":""}}),_c('div',{staticClass:"news-name",on:{"click":function($event){return _vm.showDetail(1)}}},[_c('div',[_vm._v(_vm._s(_vm.newsList[0].title))]),_c('img',{attrs:{"src":require("../../assets/news/jt23.png"),"alt":""}})]),_c('div',{class:_vm.index === 1 && _vm.isShow
                ? 'view-detail view-detail-show'
                : 'view-detail',on:{"click":function($event){_vm.isShow = false}}},[_c('div',[_vm._v(_vm._s(_vm.newsList[0].title))]),_c('div',{staticClass:"ellipsis"},[_vm._v(_vm._s(_vm.newsList[0].intro))]),_c('div',[_c('span',{on:{"click":function($event){return _vm.viewNewsDetail(_vm.newsList[0],0)}}},[_vm._v("查看全文 ")]),_c('img',{attrs:{"src":require("../../assets/news/jt22.png"),"alt":""}})])])]),_c('div',{staticClass:"middle"},[_c('div',[_c('img',{attrs:{"src":_vm.newsList[1].coverImage,"alt":""}}),_c('div',{staticClass:"news-name",on:{"click":function($event){return _vm.showDetail(2)}}},[_c('div',[_vm._v(_vm._s(_vm.newsList[1].title))]),_c('img',{attrs:{"src":require("../../assets/news/jt23.png"),"alt":""}})]),_c('div',{class:_vm.index === 2 && _vm.isShow
                  ? 'view-detail view-detail-show'
                  : 'view-detail',on:{"click":function($event){_vm.isShow = false}}},[_c('div',[_vm._v(_vm._s(_vm.newsList[1].title))]),_c('div',{staticClass:"ellipsis"},[_vm._v(_vm._s(_vm.newsList[1].intro))]),_c('div',[_c('span',{on:{"click":function($event){return _vm.viewNewsDetail(_vm.newsList[1],1)}}},[_vm._v("查看全文 ")]),_c('img',{attrs:{"src":require("../../assets/news/jt22.png"),"alt":""}})])])]),_c('div',[_c('img',{attrs:{"src":_vm.newsList[2].coverImage,"alt":""}}),_c('div',{staticClass:"news-name",on:{"click":function($event){return _vm.showDetail(3)}}},[_c('div',[_vm._v(_vm._s(_vm.newsList[2].title))]),_c('img',{attrs:{"src":require("../../assets/news/jt23.png"),"alt":""}})]),_c('div',{class:_vm.index === 3 && _vm.isShow
                  ? 'view-detail view-detail-show'
                  : 'view-detail',on:{"click":function($event){_vm.isShow = false}}},[_c('div',[_vm._v(_vm._s(_vm.newsList[2].title))]),_c('div',{staticClass:"ellipsis"},[_vm._v(_vm._s(_vm.newsList[2].intro))]),_c('div',[_c('span',{on:{"click":function($event){return _vm.viewNewsDetail(_vm.newsList[2],2)}}},[_vm._v("查看全文 ")]),_c('img',{attrs:{"src":require("../../assets/news/jt22.png"),"alt":""}})])])])]),_c('div',{staticClass:"right"},[_c('img',{attrs:{"src":_vm.newsList[3].coverImage,"alt":""}}),_c('div',{staticClass:"news-name",on:{"click":function($event){return _vm.showDetail(4)}}},[_c('div',[_vm._v(_vm._s(_vm.newsList[3].title))]),_c('img',{attrs:{"src":require("../../assets/news/jt23.png"),"alt":""}})]),_c('div',{class:_vm.index === 4 && _vm.isShow
                ? 'view-detail view-detail-show'
                : 'view-detail',on:{"click":function($event){_vm.isShow = false}}},[_c('div',[_vm._v(_vm._s(_vm.newsList[3].title))]),_c('div',{staticClass:"ellipsis"},[_vm._v(_vm._s(_vm.newsList[3].intro))]),_c('div',[_c('span',{on:{"click":function($event){return _vm.viewNewsDetail(_vm.newsList[3],3)}}},[_vm._v("查看全文 ")]),_c('img',{attrs:{"src":require("../../assets/news/jt22.png"),"alt":""}})])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('img',{attrs:{"src":require("../../assets/news/tt@2x.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message"},[_c('div',{staticClass:"title"},[_vm._v("董事长寄语")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"en-title"},[_vm._v("Chairman message")]),_c('div',{staticClass:"message-content"},[_c('img',{staticClass:"meeting",attrs:{"src":require("../../assets/news/dszjy.png"),"alt":""}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"word"},[_c('span',{staticStyle:{"display":"inline-block","width":"0.33rem"}}),_vm._v(" 在不断变化的市场环境中，我们必须保持敏锐的洞察力和创新精神。我鼓励大家勇于尝试新的思路和方法，推动公司不断创新，以适应市场的变化和需求。 我们的成功离不开客户的信任和支持。我希望我们每个人都能以客户为中心，提供优质的产品和服务，不断超越客户的期望，建立长期的合作关系。 团队合作是实现公司目标的关键。我鼓励大家相互支持、协作，共同努力，共享成功。只有通过团队的力量，我们才能取得更大的成就。 ")]),_c('div',{staticClass:"sign"},[_c('div',[_c('span',[_vm._v("董事局主席")])]),_c('div',[_c('img',{attrs:{"src":require("../../assets/news/pm.png"),"alt":""}})])]),_c('img',{staticClass:"img",attrs:{"src":require("../../assets/news/cs.png"),"alt":""}})])])])
}]

export { render, staticRenderFns }